import moment from 'moment';

const helpers = {
    /**
     * Расчет статуса документа в списке В Работе - На исполнении
     * @param {*} source 
     * @returns Название статуса для документа
     */
    getStatusByDays: function(source) {
    if(source === null)
        return 'work';

    if (source < 0)
        return 'expired';

    else if (source == 0)
        return 'today';

    else
        return 'work';
    },
    getEuolAnswerStatus: function(source) {
        switch (source.answerstatus)
        {
            case 0: return "hasAnswer";
            case 1: return "answerSended";
            default : return "";
        }
    },
    getEventStatus: function(source) {
        switch (source)
        {
            case 0: return "event_canceled";
            case 1: return "event_planned";
            case 2: return "event_performed";
            case 3: return "event_completed";
            default : return "";
        }
    },
    getEuolExecutionStatus: function(source, currentTimestamp) {
        if (source.isexecuted)
        {
            return "executed";
        }
        else
        {
            var dateInfo = moment(source.controldate);
            var currentDateInfo = moment(currentTimestamp, 'DD.MM.YYYY');

            if(dateInfo != null && dateInfo.isValid() && currentDateInfo != null && currentDateInfo.isValid())
            {
                var controlDate = dateInfo.toDate();
                var currentDate = currentDateInfo.toDate();

                var daysCount = Math.ceil( ( controlDate.getTime() - currentDate.getTime() ) / ( 1000 * 60 * 60 * 24 ) );
                if(daysCount < 0)
                {
                    return "expired";
                }
                else if( daysCount == 0 )
                {
                    return "today"
                }
                else if( daysCount == 1 )
                {
                    return "expiresin1day";
                }
                else if( daysCount == 2 )
                {
                    return "expiresin2days";
                }
                else if( daysCount == 3 )
                {
                    return "expiresin3days";
                }
            }
        }   
        
        return "";
    },
    getTaskStatus: function(statusCode) {
        switch(statusCode)
        {
            case -1:
                return "unknown";
            case 0:
                return "new";
            case 1:
                return "onExecution";                
            case 2:
                return "onExecutionCheck";
            case 3:
                return "executed";
            case 4:
                return "needRework";
            case 5:
                return "expired";
            case 7:
                return "cancelled";
            case 15:
                return "closed";
            case 16:
                return "redirected";
            case 17:
                return "redirectedControl";

            default:
                console.error(`Неизвестный статус задачи ${statusCode}`);
                break;
        }
    },
    getAdjustmentStatus: function(statusCode) {
        if (typeof statusCode === 'string');
            statusCode = parseInt(statusCode, 10);

        switch(statusCode)
        {
            case 0:
                return "unknown";
            case 1:
                return "waiting";
            case 2:
                return "adjusted";
            case 3:
                return "notAdjusted";
            case 4:
                return "rework";
            case 5:
                return "cancelled";

            default:
                console.error(`Неизвестный статус согласования ${statusCode}`);
                break;
        }
    },
    getOnlySelectedParams: function(extendedTableFilter) {
        return Object.fromEntries(Object.entries(JSON.parse(JSON.stringify(extendedTableFilter)))
        .filter(i => i[1].value != null && i[1].value !== '' && (!Array.isArray(i[1].value) || (Array.isArray(i[1].value) && i[1].value.length > 0)))
        .map((i) => {
            if (typeof i[1].value === 'string')
                return [ [i[0]], i[1].value ];
            if (typeof i[1].value === 'boolean')
                return [ [i[0]], i[1].value ];
            else if (typeof i[1].value === 'object')
            {
                if (Array.isArray(i[1].value))
                    return [ [i[0]], i[1].value.map(x => x.id ?? x)];
                else
                    return  [ [i[0]], i[1].value.id ?? i[1].value ];
            }
        }));
    },
    getSignStatus: function(statusCode) {
        if (typeof statusCode === 'string');
            statusCode = parseInt(statusCode, 10);

        switch(statusCode)
        {
            case 0:
            case 5:
                return "signed";
            case 6:
                return "signReject";
            case 7:
                return "rework";

            default:
                console.error(`Неизвестный статус подписания ${statusCode}`);
                break;
        }
    },
    /**
     * Вычисление статуса документа в списке.
     * @param {*} status значение статуса, ожидается число
     * @param {*} controlDate значение даты контроля, ожидается дата в формата YYYY-MM-DD
     * @param {*} executionDate значение даты контрольного исполнения, ожидается дата в формата YYYY-MM-DD
     * @param {*} hasResolution признак наличия контрольной резолюции, ожидается bool
     * @returns Название статуса для документа
     */
    calculateDocumentStatus(status, controlDate, executionDate, hasResolution) {
        if (!status)
            return '';

        status = parseInt(status);

        if (controlDate)
            controlDate = moment(controlDate).toDate();
        
        if (executionDate)
            executionDate = moment(executionDate).toDate();

        let currentDate = moment().toDate();
        
        if (status > 0 && status < 100)
        {
            if (hasResolution || executionDate)
            {
                if (executionDate)
                {
                    let daysCount = Math.ceil( ( controlDate.getTime() - executionDate.getTime() ) / ( 1000 * 60 * 60 * 24 ) );
                    return daysCount >= 0 ? 'done' : 'doneExpired';
                }
                else
                {
                    let daysCount = Math.ceil( ( controlDate.getTime() - currentDate.getTime() ) / ( 1000 * 60 * 60 * 24 ) );

                    if (daysCount > 0)
                        return 'work';
                    else if (daysCount == 0)
                        return 'today';
                    else if (daysCount < 0)
                        return 'expired'
                }
            }
            else
            {
                let daysCount = Math.ceil( ( controlDate.getTime() - currentDate.getTime() ) / ( 1000 * 60 * 60 * 24 ) );
                return daysCount >= 0 ? 'consideration' : 'considerationExpired';
            }
        }
        else if(status == 100)
        {
            return 'takeOffControl';
        }
        else
        {
            return '';
        }
    },
    /**
     * Вычисление статуса ОРД в списке.
     * 
     * @param statusCell ячейка со значением статуса, ожидается число ( 0 / null - нет статуса, 1 - в работе, 3 - выполнен, 5 - просрочено)
     * @return {string} Статус документа (span)
     */
     GetORDStatus(status) {
        if(status) {
            status = parseInt(status);
            switch(status)
            {
                case 1:
                    return 'work';
                case 3:
                    return 'done';
                case 5:
                    return 'expired';                    
                default:
                    return '';
            }
        }
        return '';
    },
    /**
     * Определение видимости панели расширенного фильтра на основании объекта фильтра
     * @param {*} source объект фильтра
     * @returns true, если найдено свойство со значением. false, если объект фильтра пуст
     */
    isExtendedFilterPanelVisible (source) {
        //итерируем объект по свойствам
        for (var property in source)
        {
            /**Значение свойства объекта фильтра
            *  value может быть примитивом / объектом / массивом. */
            var propValue = source[property].value;

            //если значение свойства фильтра определено
            if (propValue !== undefined && propValue !== null && propValue !== '')
            {
                //если значение является объектов
                if (typeof propValue === 'object')
                {
                    //если значение это не пустой массив
                    if (Array.isArray(propValue))
                    {
                        if (propValue.length > 0)
                        {
                            return true;
                        }
                    }
                    else
                    {
                        //фильтр не пуст
                        return true;
                    }
                }
                else
                {
                    return true;
                }
            }
        }
        return false;
    },
    getFormattedDate(date, format){
        var dateMoment = moment(date);

        if (dateMoment != null && dateMoment.isValid()) {
            return dateMoment.format(format);            
        }

        return null;
    },
    /**
     * Определения статус проекта (Проекты - Все, На согласовании)
     * @param {*} status числовое значение статуса проекта
     * @returns строковое значение имени свойста статуса
     */
    getDraftStatus(status) {
        status = parseInt(status);
        let statusName;

        switch (status) {
            case 0: statusName = 'new'; break;
            case 1: statusName = 'onRegistration'; break;
            case 2: statusName = 'rejected'; break;
            case 3: statusName = 'registered'; break;
            case 6: statusName = 'onSign'; break;
            case 7: statusName = 'signed'; break;
            case 9: statusName = 'onAdjust'; break;
            case 10: statusName = 'adjusted'; break;
            case 14: statusName = 'deleted'; break;
            case 19: statusName = 'onRework'; break;
            default: statusName = 'none'; break;
        }

        return statusName;
    },
    /**
     * Определение статуса входящего ДСП
     * @param {*} status числовое значение статуса
     * @returns строковое значение имени свойста статуса
     */
    getDSPStatus(status) {
        status = parseInt(status);
        let statusName;

        switch (status) {
            case 20: statusName = 'work'; break;
            case 30: statusName = 'today'; break;
            case 40: statusName = 'expired'; break;
            case 50: statusName = 'executed'; break;
            case 60: statusName = 'executeExpired'; break;
            case 1000: statusName = 'deleted'; break;
            default: statusName = ''; break;
        }

        return statusName;
    },
    getCommonStatusForList(listItem){
        switch(listItem.doctypeof){
            case "NPADocument":
                return true;
            case "InnerDocument":
                return listItem.iscommon === true;
            default:
                return listItem.iscommon === true && listItem.isown === false;
        }
    }
}

export default {
    install (Vue) {
        Vue.prototype.$helpers = helpers;
    }
};