import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n'
import sys from '@/services/system';

const documentcomments = {
    namespaced: true,    
    state: {
        isActive: false,
        isPending: null,
        cancellationTokenSorce: null,
        dataSource: [],

        currentDocumentId: null,
        loadingAttachments: {},
    },
    mutations: {
        SET_IS_ACTIVE(state, payload) {
            state.isActive = payload;
        },
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_CANCELLATION_TOKEN_SOURCE(state, payload) {
            state.cancellationTokenSorce = payload;
        },
        SET_DATASOURCE(state, payload) {            
            state.dataSource = payload;
        },
        SET_CURRENT_DOCUMENT_ID(state, payload) {
            state.currentDocumentId = payload;
        },
        ADD_LOADIGN_COMMENT_ATTACHMENT(state, payload) 
        {            
            if (!state.loadingAttachments[payload.id])
                state.loadingAttachments[payload.id] = [];

            let data = state.loadingAttachments[payload.id];
            
            if (!data.find(i => i.Id == payload.comment.Id))
                data.push(payload.comment);
        },
        DEL_LOADIGN_COMMENT_ATTACHMENT(state, payload) 
        {            
            if (!state.loadingAttachments[payload.id])
                return;

            var data = state.loadingAttachments[payload.id]
            var item = data.find(i => i.Id == payload.comment.Id);

            if (item)
            {
                data.splice(data.indexOf(item), 1);

                if (data.length == 0)
                    delete state.loadingAttachments[payload.id];
            }
        },
        UPDATE_lOADING_COMMENT_ATTACHMENT(state, payload)
        {
            this._vm.$_.set(state.loadingAttachments, payload.property, payload.value)
        },
        PUSH_DATASOURCE(state, payload) {
            if (!state.dataSource.find(i => i.id == payload.comment.Id))
                state.dataSource.push(payload.comment);
        },
        UPDATE_DATASOURCE(state, payload) {                        
            state.dataSource[payload.index].Attachment = payload.value.Attachment;
            state.dataSource[payload.index].Id = payload.value.Id;
            delete state.dataSource[payload.index].IsNew;
        }
    },
    actions: {  
        setPreviewSrc({ commit }, previewSrc) {
            commit({ type: 'SET_PREVIEW_SRC', previewSrc });
        },
        setDataSource({commit}, dataSource) {
            commit({ type: 'SET_DATASOURCE', dataSource });
        },
        async updateDataSource({ state, commit, rootGetters }) {

            commit('SET_DATASOURCE', []);
            if (state.cancellationTokenSorce)
                state.cancellationTokenSorce.cancel('New request started');

            commit('SET_CANCELLATION_TOKEN_SOURCE', Axios.CancelToken.source());
            commit('SET_CURRENT_DOCUMENT_ID', rootGetters['actionsource/getDataSourceDocumentId']);

            let response = await httpAPI({
                url: `api/actions/getdocumentcomments?id=${rootGetters['actionsource/getDataSourceDocumentId']}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
                headers: { 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
            });            

            if (response) {
                var loadingAttachments = state.loadingAttachments[state.currentDocumentId] ?? [];
                var data = response.data.payload;

                data.forEach(i => { i.IsActive = false; });

                if (loadingAttachments.length > 0)
                    data = data.concat(loadingAttachments);

                commit('SET_DATASOURCE', data);
            }
            else
                commit('SET_DATASOURCE', [] );
        }, 
        setIsActive({commit}, isActive) {
            commit('SET_IS_ACTIVE', isActive);
        },
        setCurrentDocumentId({ commit }, id) {
            commit({ type: 'SET_CURRENT_DOCUMENT_ID', id });
        },
        async onFileCommentPicked({ state, commit, getters, dispatch, rootGetters }, { e }) {

            await Promise.all(Array.from(e.target.files).map( async (f) => {
                    
                let currentTimeStamp = await dispatch('references/getTimestamp', null, { root: true });
                let attachment = sys.prepareAttachment(f, state.dataSource, this._vm.$notify);
                let comment = {
                    Id: sys.generateUUID(),
                    AuthorId: rootGetters['auth/getUserInfo'].workplaceId,                    
                    AuthorName: rootGetters['auth/getUserInfo'].userName,
                    EnterpriseName: rootGetters['auth/getUserInfo'].enterpriseName,
                    CommentDate: sys.dateFormat(currentTimeStamp, 'DD.MM.YYYY HH:mm:ss'),
                    Attachment: attachment,
                    IsNew: true
                };

                let currentDocumentId = rootGetters['actionsource/getDataSourceDocumentId']

                await commit('documentcomments/ADD_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true })
                await commit('documentcomments/PUSH_DATASOURCE', { comment }, { root : true });                

                if (attachment.Message)
                {
                    await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });
                    return;
                }

                let formData = new FormData();
                formData.append('file', f);

                try 
                {
                    let response = await httpAPI({
                        url: `/api/actions/savecommentattachment?id=${currentDocumentId}&fileName=${attachment.Name}&fileSize=${attachment.Length}`,
                        method: 'POST',
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data', 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
                        onUploadProgress: (progressEvent) => {
                            let index = getters.getLoadingAttachmnets[currentDocumentId].indexOf(getters.getLoadingAttachmnets[currentDocumentId].find(i => i.Id == comment.Id));
                            let progress = `${Math.trunc((100/progressEvent.total)*progressEvent.loaded)}%`;
                            if (attachment.Progress != progress)
                                commit('UPDATE_lOADING_COMMENT_ATTACHMENT', { property: `${currentDocumentId}[${index}].Attachment.Progress`, value: progress });                            
                        },
                        cancelToken: attachment.CancelTokenSource.token,
                        skipErrorHandler: true
                    });

                    if (response.data.success && response.data.payload.Result == "OK")
                    {
                        await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });
                        
                        if (currentDocumentId == state.currentDocumentId)
                        {
                            let index = state.dataSource.indexOf(comment);
                            await commit('UPDATE_DATASOURCE', { index: index, value: response.data.payload.Data.Object })
                            await dispatch('actionsource/updatePageCounter', { tabId: '0202150', increase: true }, { root: true });
                        }

                        this._vm.$notify.success(i18n.t('Файл_filename_успешно_загружен.message', {filename: attachment.Name}));
                    }
                    else
                    {
                        if (currentDocumentId == state.currentDocumentId)
                        {
                            comment.Attachment.Message = response.data.success ? response.data.payload.Message : response.data.message;
                            delete  comment.Attachment.Progress;

                            let index = state.dataSource.indexOf(comment);
                            await commit('UPDATE_DATASOURCE', { index: index, value: comment })
                            await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });
                            //await commit('documentcomments/SET_DATASOURCE', state.dataSource.map(x => x), { root: true });
                        }

                        this._vm.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: attachment.Name, reason: response.data.success ? response.data.payload.Message : response.data.message }));
                    }
                }
                catch (exception) 
                {
                    comment.Atachment.Message = Axios.isCancel(exception) ? i18n.t('Загрузка_отменена') : i18n.t('Ошибка_загрузки_файла');
                    delete  comment.Attachment.Progress;                    
                    let index = state.dataSource.indexOf(comment);
                    await commit('UPDATE_DATASOURCE', { index: index, value: comment })
                    await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });

                    attachment.CancelTokenSource.cancel();
                    //await commit('documentcomments/SET_DATASOURCE', { dataSource: state.dataSource.map(x => x) }, { root: true });
                    this._vm.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename.message', { filename: attachment.Name }));
                }
            }));

            e.target.value = null;
        },
        async onFileIQalaCommentPicked({ state, commit, getters, dispatch, rootGetters }, { e }) {

            await Promise.all(Array.from(e.target.files).map( async (f) => {
                    
                let currentTimeStamp = await dispatch('references/getTimestamp', null, { root: true });
                let attachment = sys.prepareAttachment(f, state.dataSource, this._vm.$notify);
                let comment = {
                    Id: sys.generateUUID(),
                    AuthorId: rootGetters['auth/getUserInfo'].workplaceId,                    
                    AuthorName: rootGetters['auth/getUserInfo'].userName,
                    EnterpriseName: rootGetters['auth/getUserInfo'].enterpriseName,
                    CommentDate: sys.dateFormat(currentTimeStamp, 'DD.MM.YYYY HH:mm:ss'),
                    Attachment: attachment,
                    IsNew: true
                };

                let currentDocumentId = rootGetters['actionsource/getDataSourceDocumentId']

                await commit('documentcomments/ADD_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true })
                await commit('documentcomments/PUSH_DATASOURCE', { comment }, { root : true });                

                if (attachment.Message)
                {
                    await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });
                    return;
                }

                let formData = new FormData();
                formData.append('file', f);

                try 
                {
                    let response = await httpAPI({
                        url: `/api/actions/saveiqalacommentattachment?id=${currentDocumentId}&fileName=${attachment.Name}&fileSize=${attachment.Length}`,
                        method: 'POST',
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data', 'isCommon': rootGetters['actionsource/isDataSourceCommon'] },
                        onUploadProgress: (progressEvent) => {
                            let index = getters.getLoadingAttachmnets[currentDocumentId].indexOf(getters.getLoadingAttachmnets[currentDocumentId].find(i => i.Id == comment.Id));
                            let progress = `${Math.trunc((100/progressEvent.total)*progressEvent.loaded)}%`;
                            if (attachment.Progress != progress)
                                commit('UPDATE_lOADING_COMMENT_ATTACHMENT', { property: `${currentDocumentId}[${index}].Attachment.Progress`, value: progress });                            
                        },
                        cancelToken: attachment.CancelTokenSource.token,
                        skipErrorHandler: true
                    });

                    if (response.data.success && response.data.payload.Result == "OK")
                    {
                        await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });
                        
                        if (currentDocumentId == state.currentDocumentId)
                        {
                            let index = state.dataSource.indexOf(comment);
                            await commit('UPDATE_DATASOURCE', { index: index, value: response.data.payload.Data.Object })
                            await dispatch('actionsource/updatePageCounter', { tabId: '0202150', increase: true }, { root: true });
                        }

                        this._vm.$notify.success(i18n.t('Файл_filename_успешно_загружен.message', {filename: attachment.Name}));
                    }
                    else
                    {
                        if (currentDocumentId == state.currentDocumentId)
                        {
                            comment.Attachment.Message = response.data.success ? response.data.payload.Message : response.data.message;
                            delete  comment.Attachment.Progress;

                            let index = state.dataSource.indexOf(comment);
                            await commit('UPDATE_DATASOURCE', { index: index, value: comment })
                            await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });
                            //await commit('documentcomments/SET_DATASOURCE', state.dataSource.map(x => x), { root: true });
                        }

                        this._vm.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: attachment.Name, reason: response.data.success ? response.data.payload.Message : response.data.message }));
                    }
                }
                catch (exception) 
                {
                    comment.Atachment.Message = Axios.isCancel(exception) ? i18n.t('Загрузка_отменена') : i18n.t('Ошибка_загрузки_файла');
                    delete  comment.Attachment.Progress;                    
                    let index = state.dataSource.indexOf(comment);
                    await commit('UPDATE_DATASOURCE', { index: index, value: comment })
                    await commit('documentcomments/DEL_LOADIGN_COMMENT_ATTACHMENT', { id: currentDocumentId, comment: comment }, { root: true });

                    attachment.CancelTokenSource.cancel();
                    //await commit('documentcomments/SET_DATASOURCE', { dataSource: state.dataSource.map(x => x) }, { root: true });
                    this._vm.$notify.alert(i18n.t('Ошибка_загрузки_файла:_filename.message', { filename: attachment.Name }));
                }
            }));

            e.target.value = null;
        },
    },
    getters: {
        getLoadingAttachmnets: s => s.loadingAttachments,
        getIsActive: s => s.isActive,
        getIsPending: s => s.isPendig,
        getDataSource: s => s.dataSource,        
    },
};

export default documentcomments;
