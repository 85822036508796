<template>

    <div v-if="initialized && dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">
            <div class="top-btns-bar-right-section">

                <v-icon
                    class="extend-filter"
                    v-tooltip.left-center="$t('Расширенный_поиск')"
                    left
                    @click="openExtendedFilterDialog"
                >
                    mdi-filter
                </v-icon>

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>
        </Toolbar>

        <FilterPanel
            :dataSource="filterDataSource"
            v-bind:status.sync="category"
            v-bind:substatus.sync="subCategory"
            v-bind:year.sync="complitYear"
        >
            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />
        </FilterPanel>

        <v-row no-gutters>

            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :headers="headers"
                    :loading="loading"
                    :items="requests"
                    :item-class="itemRowBackground"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[ 5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents|CitizenStatements.Document' })">
                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.regdate`]="{ item }">
                        {{ formatDate(item.regdate) }}
                    </template>

                    <template v-slot:[`item.recdate`]="{ item }">
                        {{ formatDate(item.recdate) }}
                    </template>

                    <template v-slot:[`item.execdate`]="{ item }">
                        {{ formatDate(item.execdate) }}
                    </template>

                    <template v-slot:[`item.registrationstate`]="{ item }">
                        <StatusChip :status="getStatus(item)"/>

                        <!-- <v-chip
                        class="table-label"
                        small
                        label
                        :color="getStatusColor(item)"
                        dark
                        >
                            <div>{{ getStatusText(item) }}</div>
                        </v-chip> -->
                    </template>

                    <template v-slot:[`item.declarant`]="{ item }">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.declarant">
                                {{item.declarant}}
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.annotation`]="{ item }">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.annotation">
                                {{item.annotation}}
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.commentstate`]="{ item }">
                        <div class="icon-with-badge">
                            <v-icon v-if="item.commentstate" v-tooltip.top-center="'Есть комментарии'" class="has-unreaded-comments">
                                fas fa-comment-alt
                            </v-icon>
                            <span v-if="item.commentstate" v-tooltip.top-center="'Есть комментарии'" class="badge">
                                {{ item.commentstate  > 99 ? '99+' : item.commentstate }}
                            </span>
                        </div>
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>
        <ExtendedFilter v-model="extendedTableFilter" ref="ExtendedFilterDialog" />
    </div>
</template>

<script>
import _ from 'lodash';
import Axios from 'axios';

import { httpAPI } from "@/api/httpAPI";
import FilterPanel from '@/components/FilterPanel_3Level.vue';
import ExtendedFilterPanel from '@/components/ExtendedFilterPanel.vue';
import ExtendedFilter from '@/components/dialogs/extended-filters/CSCExFilter';

import { IQALA_SET_TABLE_OPTION, IQALA_SET_TABLE_SEARCH, IQALA_SET_TABLE_EXTANDED_FILTER } from '@/store/mutation-types'
import { mapActions } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import StatusChip from '@/components/StatusChip'

export default {
    name: "IQalaList",
    components: {
        FilterPanel,
        ExtendedFilter,
        ExtendedFilterPanel,
        Toolbar,
        StatusChip
    },
    data () {
        return {
            initialized: false,
            cancellationTokenSorce: null,
            loading: true,
            total: 0,
            requests: [],
            dataSource: null,
            filterDataSource: null,
            prevTableFilterObject: null,
            paginationPageCount: 0,
            urls: [],
        }
    },
    async created()
    {
        this.setOverlayVisible({ visible: true });

        if (this.cancellationTokenSorce)
            this.cancellationTokenSorce.cancel('New request started');

        this.cancellationTokenSorce = Axios.CancelToken.source();

        let response = await httpAPI({
            url: `api/actions/collection?type=Documents.CSC`,
            method: 'GET',
            cancelToken: this.cancellationTokenSorce.token,
        });

        if (response) {
            let filterResponse = await httpAPI({
                url: `/api/csc/requests/filter`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse)
                this.filterDataSource = filterResponse?.data?.payload;

            this.dataSource = response?.data?.payload;
        }

        this.initialized = true;
        this.setOverlayVisible({ visible: false });
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['iqala/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit(IQALA_SET_TABLE_OPTION, newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['iqala/GetTableSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('iqala/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit(IQALA_SET_TABLE_SEARCH, v);
            }, 500)
        },
        category: {
            get: function() {
                return this.$store.getters['iqala/GetCategory'];
            },
            set: function(newValue) {
                this.$store.commit('iqala/SET_CATEGORY', newValue);
                this.$store.commit('iqala/SET_TABLE_OPTION_PAGE', 1);
            }
        },
        subCategory: {
            get: function() {
                let subs = this.$store.getters['iqala/GetSubCategories'];
                return subs.find(x => x.category == this.category)?.value;
            },
            set: function(newValue) {
                this.$store.commit('iqala/PUSH_SUB_CATEGORY', { category: this.category, value: newValue } );
                this.$store.commit('iqala/SET_TABLE_OPTION_PAGE', 1);
            }
        },
        complitYear: {
            get: function() {
                let years = this.$store.getters['iqala/GetYears'];
                return years.find(x => x.subCategory == this.subCategory)?.value;
            },
            set: function(newValue) {
                this.$store.commit('iqala/PUSH_YEAR', { subCategory: this.subCategory, value: newValue } );
                this.$store.commit('iqala/SET_TABLE_OPTION_PAGE', 1);
            }
        },
        extendedTableFilter: {
            get: function() {
                return this.$store.getters['iqala/GetTableExtandedFilter'];
            },
            set: function(newValue) {
                this.$store.commit(IQALA_SET_TABLE_EXTANDED_FILTER, newValue);
            }
        },
        filter () {   
            return {
                ...this.options,
                SearchStr: this.search,
                Category: this.category,
                ArchYear: this.complitYear,
                RequestStatus: this.subCategory,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
        headers() {
            const headers = [
                { text: this.$t('Рег._номер'), value: 'regnumber', width: '150px', orderby: 2 },
                { text: this.$t('Рег._дата'), value: 'regdate', width: '120px', orderby: 3 },                    
                { text: this.$t('ИИН/БИН'), value: 'iin_bin', width: '120px', orderby: 13 },
                { text: this.$t('Заявитель'), value: 'declarant', width: '15%', orderby: 6 },
                { text: this.$t('Услуга'), value: 'annotation', orderby: 12 },
                { text: this.$t(''), value: 'commentstate', orderby: 26, width: '50px',sortable: false },
                { text: this.$t('Исх._№_заявления'), value: 'uniquenumber', width: '170px', orderby: 22 },                
                { text: this.$t('Дата_создания'), value: 'recdate', width: '130px', orderby: 21 },
                { text: this.$t('Дата_исполнения'), value: 'execdate', width: '145px', orderby: 14 },
                { text: this.$t('Статус'), value: 'registrationstate', width: '100px', orderby: 16, sortable: false }
            ];

            if (["OnRegistration"].includes(this.filter.Category)) {
                return headers.filter(h => !['regnumber', 'regdate', 'registrationstate', 'execdate'].includes(h.value))
            }
            else if (["Completed"].includes(this.filter.Category)) {
                return headers.filter(h => !['recdate'].includes(h.value))
            }
            else {
                return headers.filter(h => !['recdate', 'execdate'].includes(h.value))
            }
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
    methods:
    {
        ...mapActions(['setOverlayVisible']),
        getStatus(item) {
            let key = '';

            if (item.registrationstate == 1 && item.executionstatus == 0 && item.paymentstatus != 2 && ![1, 2].includes(item.signaturestatus) &&  !item.hasrecipients && !item.hasresolutions) key = 'acepted'; //принят            
            if (item.registrationstate == 1 && item.executionstatus == 0 && item.paymentstatus != 2 &&  ![1, 2].includes(item.signaturestatus) && item.hasrecipients && !item.hasresolutions) key = 'consideration';
            if (item.paymentstatus == 2 && ![1, 2].includes(item.signaturestatus)) key = 'waitPayment';
            if (item.paymentstatus == 3 && ![1, 2].includes(item.signaturestatus)) key = 'paymentMade';
            if (item.registrationstate == 1 && item.executionstatus == 0 && item.paymentstatus != 2 && ![1, 2].includes(item.signaturestatus) && item.hasresolutions) key = 'onExecution';
            if (item.signaturestatus == 1) key = 'onSignatureOfApplicant';
            if (item.signaturestatus == 2) key = 'onSignatureOfServiceProvider';
            if (item.executionstatus == 1) key = 'executed';
            if (item.executionstatus == 2) key = 'refusal';

            return this.avaibleStatuses[key];
        },
        async onToolbarClick (event, btn) {
            switch(btn.Action)
            {
                case 'Refresh':
                {
                    let filterResponse = await httpAPI({
                        url: `/api/csc/requests/filter`,
                        method: 'GET',
                        cancelToken: this.cancellationTokenSorce.token,
                    });

                    if (filterResponse)
                        this.filterDataSource = filterResponse?.data?.payload;
                        
                    this.getData(true);
                    break;
                }
                case 'Export':
                    {
                        this.$store.dispatch('setOverlayVisible', { visible: true, text: 'Отчет_формируется...' });

                        let filter = this.getTableFilterObject();
                        delete filter.Limit;
                        delete filter.Offset;
                        let exportURI = `api/csc/requests/export?filter=${JSON.stringify(filter)}`;

                        let response = await httpAPI({
                            url: exportURI,
                            method: 'GET',
                            responseType: 'blob',
                        });

                        this.$store.dispatch('setOverlayVisible', { visible: false });

                        if (response) {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            this.urls.push(url);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${this.$t("Экспорт_списка_заявок")} ${this.$t('от_время')} ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}.xls` );
                            document.body.appendChild(link);
                            link.click();
                        }

                        break;
                    }
                default:
                    break;
            }
        },
        async getData(isRefresh = false) {
            let tableFilterObject = this.getTableFilterObject();

            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, tableFilterObject))
                return;
            if (tableFilterObject.Category == "Completed" && tableFilterObject.ArchYear == "0")
                return;
                
            this.prevTableFilterObject = tableFilterObject;            
            this.total = 0;
            this.requests = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/csc/requests/list?filter=${JSON.stringify(tableFilterObject)}`,                        
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            
            this.total = response?.data?.payload?.total ?? 0;
            this.requests = response?.data?.payload?.data ?? [];
            let btn = this.dataSource.Data.Menu.find(item=>item.Action=="Export");
            if (btn)
                btn.Disabled=this.requests.length == 0;
            this.loading = false;
        },
        getTableFilterObject() {
            var { sortBy, sortDesc, page, itemsPerPage, SearchStr, Category, ArchYear, RequestStatus, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let OrderBy = this.headers.find(i => i.value == (sortBy.length <= 0 ? 'null' : sortBy[0]))?.orderby ?? -1;
            if (Array.isArray(OrderBy))
            {
                OrderDir = OrderBy.map(() => OrderDir);
            }
            if (["Completed"].includes(this.filter.Category))
                return { Category, ArchYear, RequestStatus, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData }
            return { Category, RequestStatus, SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData };
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        async openExtendedFilterDialog () {
            try 
            {
                await this.$refs.ExtendedFilterDialog.open();
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }            
        },
        formatDate (value) {
            if (!value)
                return '';

            return this.$moment(value).format('DD.MM.YYYY');
        }
    }
}
</script>