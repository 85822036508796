<template>
    <v-main>

        <Header />

        <div class="content-box" :key="$i18n.locale">
            <router-view :key="this.$route.fullPath" />
        </div>

        <div class="version-info-in-page">
            {{ $t('Версия') }}: {{ getVersion() }}
        </div>

        <PersonModal ref="PersonModalRef"/>
        <DocumentVersionsDlg ref="DocumentVersionsDlgRef" />

        <RegisterDocumentDlg v-if="isRegisterDocumentVisible" />
        <SelectMembersNewDlg v-if="isSelectMembersNewVisible" />
        <PDFViewDlg v-if="isPdfViewVisible" />
        <BeginAdjustmentDlg v-if="isBeginAdjustmentVisible" />
        <CreateNewVersionDlg v-if="isCreateNewVersionVisible" />
        <RejectDlg v-if="isRejectVisible"/>
        <SetAdjustmentDlg v-if="isSetAdjustmentVisible" />
        <SetSignDlg v-if="isSetSignVisible" />
        <OrderCounterDlg v-if="isOrderCounterVisible" />
        <ExecutersGroupDlg v-if="isExecutersGroupVisible" />
        <StartProcessDlg v-if="isStartProcessVisible" />
        <AddDocumentLinkDlg v-if="isAddDocumentLinkVisible" />
        <AttachDuplicateDlg v-if="isAttachDuplicatesVisible" />
        <SelectNomenclatureDlg v-if="isSelectNomenclatureVisible" />
        <CSCRegistryDlg v-if="isCscRegistryVisible" />
        <CSCAnswerDlg v-if="isCscAnswerVisible" />
        <CSCFormAnAnswerDlg v-if="isCscFormAnAnswerVisible" />
        <CSCFormAnAnswer060X0001Dlg v-if="isCscFormAnAnswer060X0001Visible" />
        <CSCRejectionDlg v-if="isCscRejectionVisible" />
        <CSCInvoiceDlg v-if="isCscInvoiceVisible" />
        <CSCCommentDlg v-if="isCscCommentVisible" />
        <CSCContractInfoDlg v-if="isCscContractInfoVisible" />
        <SetDocumentControlDlg v-if="isSetDocumentControlVisible" />
        <ChangeControlDlg v-if="isChangeControlVisible" />
        <ProlongateControlDlg v-if="isProlongateControlVisible" />
        <ChangeEuolStatementUniqueNumberDlg v-if="isChangeEuolStatementUniqueNumberVisible" />
        <ProlongateDlg v-if="isProlongateVisible" />
        <PromptPasswordDlg v-if="isPromptPasswordVisible" />
        <PromptProfileDlg v-if="isPromptProfileVisible" />
        <PrintFishkaDlg v-if="isPrintFishkaVisible" />
        <RegisterSectionDlg v-if="isRegisterSectionVisible" />
        <ExecuterAnswerDlg v-if="isExecuterAnswerVisible" />
        <ExecuterAnswerViewDlg v-if="isExecuterAnswerViewVisible" />
        <RegisterEuolStatementDlg v-if="isRegisterEuolStatementPrimaryVisible" />
        <RegisterEuolStatementAsDlg v-if="isRegisterEuolStatementAsVisible" />
        <EUOLAddExecutionDlg v-if="isEuolAddExecutionVisible" />
        <GovermentRegistrationDlg v-if="isGovermentRegistrationVisible" />
        <NotifyInactiveDlg v-if="isNotifyInactiveVisible" />
        <CertificateExpiresDlg v-if="isCertificateExpiresVisible" />
        <NotifySignErrorConfirmDlg v-if="isNotifySignErrorConfirmVisible" />
        <NextPeriodDlg v-if="isNextPeriodDateDlgVisible" /> 
        <SelectEnterpriseDlg v-if="isSelectEnterpriseDlgVisible" /> 
        <SendForExecutionDlg v-if="isSendForExecutionVisible" />
        <AddMeetingQuestionDlg v-if="isAddMeetingQuestionVisible" />
        <FormGeneratorDlg v-if="isFormGeneratorVisible" />
        <TableGeneratorDlg v-if="isTableGeneratorVisible" />
        <SendReportOnExecutionDlg v-if="isSendReportOnExecutionVisible" />
        <ReturnReasonDlg v-if="isReturnReasonDlgVisible" />
        <AddProtocolLinkDlg v-if="isAddProtocolLinkDlgVisible" />

        <input
            type="file"
            style="display: none"
            ref="inputFile"
            multiple
            @change="onInputChange"
        />

        <input
            type="file"
            style="display: none"
            ref="inputFileToComment"
            multiple
            @change="onInputFileToCommentChange"
        />

        <input
            type="file"
            style="display: none"
            ref="inputFileToCommentIQala"
            multiple
            @change="onInputFileToCommentIQalaChange"
        />

    </v-main>
</template>

<script>
//import { httpAPI } from "@/api/httpAPI";
import { httpAPI, errorHandler } from "@/api/httpAPI";
import Header from '@/components/Header.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PersonModal from '@/components/dialogs/PersonModal';
import DocumentVersionsDlg from '@/components/dialogs/DocumentVersionsDlg';
import sys from '@/services/system';

export default {
    components: {
    Header,
    PersonModal,
    DocumentVersionsDlg,
    RegisterDocumentDlg: () => import('@/components/dialogs/RegisterDocumentDlg'),
    SelectMembersNewDlg: () => import('@/components/dialogs/SelectMembersNewDlg'),
    PDFViewDlg: () => import('@/components/dialogs/PDFViewDlg'),
    BeginAdjustmentDlg: () => import('@/components/dialogs/BeginAdjustmentDlg'),
    CreateNewVersionDlg: () => import('@/components/dialogs/CreateNewVersionDlg'),
    RejectDlg: () => import('@/components/dialogs/RejectDlg'),
    SetAdjustmentDlg: () => import('@/components/dialogs/SetAdjustmentDlg'),
    SetSignDlg: () => import('@/components/dialogs/SetSignDlg'),
    OrderCounterDlg: () => import('@/components/dialogs/OrderCounterDlg'),
    ExecutersGroupDlg: () => import('@/components/dialogs/ExecutersGroupDlg'),
    StartProcessDlg: () => import('@/components/dialogs/StartProcessDlg'),
    AddDocumentLinkDlg: () => import('@/components/dialogs/AddDocumentLinkDlg'),
    AttachDuplicateDlg: () => import('@/components/dialogs/AttachDuplicateDlg'),
    SelectNomenclatureDlg: () => import('@/components/dialogs/SelectNomenclatureDlg'),
    CSCRegistryDlg: () => import('@/components/dialogs/CSCRegistryDlg'),
    CSCAnswerDlg: () => import('@/components/dialogs/CSCAnswerDlg'),
    CSCFormAnAnswerDlg: () => import('@/components/dialogs/CSCFormAnAnswerDlg'),
    CSCFormAnAnswer060X0001Dlg: () => import('@/components/dialogs/CSCFormAnAnswer060X0001Dlg'),
    CSCRejectionDlg: () => import('@/components/dialogs/CSCRejectionDlg'),
    CSCInvoiceDlg: () => import('@/components/dialogs/CSCInvoiceDlg'),
    CSCCommentDlg: () => import('@/components/dialogs/CSCCommentDlg'),
    CSCContractInfoDlg: () => import('@/components/dialogs/CSCContractInfoDlg'),
    SetDocumentControlDlg: () => import('@/components/dialogs/SetDocumentControlDlg'),
    ChangeControlDlg: () => import('@/components/dialogs/ChangeControlDlg'),
    ProlongateControlDlg: () => import('@/components/dialogs/ProlongateControlDlg'),
    ChangeEuolStatementUniqueNumberDlg: () => import('@/components/dialogs/ChangeEuolStatementUniqueNumberDlg'),
    ProlongateDlg: () => import('@/components/dialogs/ProlongateDlg'),
    PromptPasswordDlg: () => import('@/components/dialogs/signing/PromptPasswordDlg'),
    PromptProfileDlg: () => import('@/components/dialogs/signing/PromptProfileDlg'),
    PrintFishkaDlg: () => import('@/components/dialogs/PrintFishkaDlg'),
    RegisterSectionDlg: () => import('@/components/dialogs/RegisterSectionDlg'),
    ExecuterAnswerDlg: () => import('@/components/dialogs/ExecuterAnswerDlg'),
    ExecuterAnswerViewDlg: () => import('@/components/dialogs/ExecuterAnswerViewDlg'),
    RegisterEuolStatementDlg: () => import('@/components/dialogs/RegisterEuolStatementDlg'),
    RegisterEuolStatementAsDlg: () => import('@/components/dialogs/RegisterEuolStatementAsDlg'),
    EUOLAddExecutionDlg: () => import('@/components/dialogs/EUOLAddExecutionDlg'),
    GovermentRegistrationDlg: () => import('@/components/dialogs/GovermentRegistrationDlg'),
    NotifyInactiveDlg: () => import('@/components/dialogs/NotifyInactiveDlg'),
    CertificateExpiresDlg: () => import('@/components/dialogs/CertificateExpiresDlg'),
    NotifySignErrorConfirmDlg: () => import('@/components/dialogs/NotifySignErrorConfirmDlg'),
    NextPeriodDlg: () => import('@/components/dialogs/NextPeriodDateDlg'),
    SelectEnterpriseDlg: () => import('@/components/dialogs/SelectEnterpriseDlg'),
    SendForExecutionDlg: () => import('@/components/dialogs/SendForExecutionDlg'),
    AddMeetingQuestionDlg: () => import('@/views/EventsManagment/modals/AddMeetingQuestionDlg'),
    FormGeneratorDlg: () => import('@/components/dialogs/FormGeneratorDlg'),
    TableGeneratorDlg: () => import('@/components/dialogs/TableGeneratorDlg'),
    SendReportOnExecutionDlg: () => import('@/components/dialogs/SendReportOnExecutionDlg'),
    ReturnReasonDlg: () => import('@/components/dialogs/ReturnReasonDlg'),
    AddProtocolLinkDlg: () => import('@/components/dialogs/AddProtocolLinkDlg.vue')
},
    data: () => ({
        //
        urls: []
    }),
    computed:{
        ...mapGetters('attachments', ['getCurrentDocumentId']),
        ...mapGetters('actionsource', ['getActiveTabName']),
        ...mapGetters('dialogs/registerDocument', { isRegisterDocumentVisible : 'isVisible' }),
        ...mapGetters('dialogs/selectMembersNew', { isSelectMembersNewVisible : 'isVisible' }),
        ...mapGetters('dialogs/pdfView', { isPdfViewVisible : 'isVisible' }),
        ...mapGetters('dialogs/beginAdjustment', { isBeginAdjustmentVisible : 'isVisible' }),
        ...mapGetters('dialogs/createNewVersion', { isCreateNewVersionVisible : 'isVisible' }),
        ...mapGetters('dialogs/reject', { isRejectVisible: 'isVisible' }),
        ...mapGetters('dialogs/setAdjustment', { isSetAdjustmentVisible: 'isVisible' }),
        ...mapGetters('dialogs/setSign', { isSetSignVisible: 'isVisible' }),
        ...mapGetters('dialogs/orderCounter', { isOrderCounterVisible: 'isVisible' }),
        ...mapGetters('dialogs/executersGroup', { isExecutersGroupVisible: 'isVisible' }),
        ...mapGetters('dialogs/startProcess', { isStartProcessVisible: 'isVisible' }),
        ...mapGetters('dialogs/addDocumentLink', { isAddDocumentLinkVisible: 'isVisible' }),
        ...mapGetters('dialogs/attachDuplicates', { isAttachDuplicatesVisible: 'isVisible' }),
        ...mapGetters('dialogs/selectNomenclature', { isSelectNomenclatureVisible: 'isVisible' }),
        ...mapGetters('dialogs/cscRegistry', { isCscRegistryVisible: 'getVisible' }),
        ...mapGetters('dialogs/cscAnswer', { isCscAnswerVisible: 'isVisible' }),
        ...mapGetters('dialogs/cscFormAnAnswer', { isCscFormAnAnswerVisible: 'isVisible' }),
        ...mapGetters('dialogs/cscFormAnAnswer060X0001', { isCscFormAnAnswer060X0001Visible: 'isVisible' }),
        ...mapGetters('dialogs/cscRejection', { isCscRejectionVisible: 'isVisible' }),
        ...mapGetters('dialogs/cscInvoice', { isCscInvoiceVisible: 'isVisible' }),
        ...mapGetters('dialogs/cscComment', { isCscCommentVisible: 'isVisible' }),
        ...mapGetters('dialogs/cscContractInfo', { isCscContractInfoVisible: 'isVisible' }),
        ...mapGetters('dialogs/setDocumentControl', { isSetDocumentControlVisible: 'isVisible' }),
        ...mapGetters('dialogs/changeControl', { isChangeControlVisible: 'isVisible' }),
        ...mapGetters('dialogs/prolongateControl', { isProlongateControlVisible: 'isVisible' }),
        ...mapGetters('dialogs/changeEuolStatementUniqueNumber', { isChangeEuolStatementUniqueNumberVisible: 'isVisible' }),
        ...mapGetters('dialogs/prolongate', { isProlongateVisible: 'isVisible' }),
        ...mapGetters('signing', [ 'isPromptPasswordVisible', 'isPromptProfileVisible' ]),
        ...mapGetters('dialogs/printFishka', { isPrintFishkaVisible: 'isVisible' }),
        ...mapGetters('dialogs/registerSection', { isRegisterSectionVisible: 'isVisible' }),
        ...mapGetters('dialogs/executerAnswer', { isExecuterAnswerVisible: 'isVisible' }),
        ...mapGetters('dialogs/executerAnswerView', { isExecuterAnswerViewVisible: 'isVisible' }),
        ...mapGetters('dialogs/registerEuolStatementPrimary', { isRegisterEuolStatementPrimaryVisible: 'isVisible' }),
        ...mapGetters('dialogs/registerEuolStatementAs', { isRegisterEuolStatementAsVisible: 'isVisible' }),
        ...mapGetters('dialogs/euolAddExecution', { isEuolAddExecutionVisible: 'isVisible' }),
        ...mapGetters('dialogs/govermentRegistration', { isGovermentRegistrationVisible: 'isVisible' }),
        ...mapGetters('dialogs/notifyInactive', { isNotifyInactiveVisible: 'isVisible', isNotifyInProcess: 'isNotifyInProcess' }),
        ...mapGetters('dialogs/certificateExpires', { isCertificateExpiresVisible: 'isVisible' }),
        ...mapGetters('dialogs/notifySignErrorConfirm', { isNotifySignErrorConfirmVisible: 'isVisible' }),
        ...mapGetters('dialogs/nextPeriodDate', { isNextPeriodDateDlgVisible: 'isVisible' }),
        ...mapGetters('dialogs/selectEnterprise', { isSelectEnterpriseDlgVisible: 'isVisible' }),
        ...mapGetters('dialogs/sendForExecution', { isSendForExecutionVisible: 'isVisible' }),
        ...mapGetters('dialogs/addMeetingQuestion', { isAddMeetingQuestionVisible: 'isVisible' }),
        ...mapGetters('dialogs/formGenerator', { isFormGeneratorVisible: 'isVisible' }),
        ...mapGetters('dialogs/tableGenerator', { isTableGeneratorVisible: 'isVisible' }),
        ...mapGetters('dialogs/sendreportonexecution', {isSendReportOnExecutionVisible: 'isVisible'}),
        ...mapGetters('dialogs/returnreason', {isReturnReasonDlgVisible: 'isVisible'}),
        ...mapGetters('dialogs/addProtocolLink', {isAddProtocolLinkDlgVisible: 'isVisible'}),

        ...mapGetters({ userRegion: 'getUserRegion' }),
        isC5Configuration() {
            return process.env.VUE_APP_CONFIGURATION == sys.Configurations.C5
        },
        isC4Configuration() {
            return process.env.VUE_APP_CONFIGURATION == sys.Configurations.C4
        }
    },
    methods: {
        ...mapActions('attachments', ['setCurrentDocumentId', 'onFilePicked', 'updateDataSource']),
        ...mapActions('actionsource', ['loadDataSource']),
        ...mapActions('documentcomments', ['onFileCommentPicked', 'onFileIQalaCommentPicked']),
        

        ...mapMutations({ setUserActivityState: 'SET_USER_ACTIVITY_STATE' }),
        onInputChange(e) {            
            this.onFilePicked({ e, id: this.getCurrentDocumentId });
        },
        onInputFileToCommentChange(e) {
            this.onFileCommentPicked({ e, id: this.getCurrentDocumentId })
        },
        onInputFileToCommentIQalaChange(e) {
            this.onFileIQalaCommentPicked({ e, id: this.getCurrentDocumentId })
        },
        getVersion() {
            let isDebug = process?.env?.VUE_APP_DEBUG === "true";
            let version = process?.env?.VUE_APP_VERSION ?? "0.0.0.0.0000"

            if (this.isC4Configuration)
                return isDebug ? `${version}/DEBUG` : `${version}/RELEASE`;

            if (this.isC5Configuration)
                return `${version}/${this.userRegion}`;
        }
    },
    mounted() {
        /*
            Глобальные события клика по плашкам разных типов
            - show-employee-information
            - show-workplace-information
            - show-enterprise-information
        */
        this.$eventBus.$on('show-employee-information', async id => {
            let promise = httpAPI({ url: `/api/references/employee/${id}`, method: 'GET' });
            await this.$refs.PersonModalRef.open(promise);
        });

        this.$eventBus.$on('show-workplace-information', async id =>
        {
            let promise = httpAPI({ url: `/api/references/workplace/${id}`, method: 'GET' });
            await this.$refs.PersonModalRef.open(promise);
        });

        this.$eventBus.$on('show-enterprise-information', async () =>
        {
            console.log('show-enterprise-information event triggered!');
        });

        this.$eventBus.$on('open-document', async data =>
        {
            this.loadDataSource({ id: data.id, openType: data.type, navigate: true, common: data.common ?? false });
        });

        this.$eventBus.$on('open-linked-document', async data =>
        {
            this.loadDataSource(
            { 
                id: "OpenLinkedPlaceholderId",
                openType: data.type,
                navigate: true,
                common: data.common ?? false,
                params: { ...data, isLinkedDocument: true }
            });
        });

        this.$eventBus.$on('show-versions-information', async data =>
        {
            try
            {
                if (this.$refs.DocumentVersionsDlgRef) {
                    let { id, common } = await this.$refs.DocumentVersionsDlgRef.open(data.documentId);
                    this.loadDataSource({ id, openType: "Chancellery|Documents.Document", navigate: true, common: common ?? false });
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
        });

        this.$eventBus.$on('on-input-file', async id => {
            let needUpdateSource = this.getCurrentDocumentId != id;
            await this.setCurrentDocumentId(id);
            
            if (needUpdateSource)
                await this.updateDataSource();
            
            this.$refs.inputFile.click();
        });

        this.$eventBus.$on('on-input-file-to-comment', async id => {
            console.log(id);
            this.$refs.inputFileToComment.click();
        });

        this.$eventBus.$on('on-input-file-to-comment-iqala', async id => {
            console.log(id);
            this.$refs.inputFileToCommentIQala.click();
        });

        this.$eventBus.$on('on-get-report', async repData => {
            //console.log(repData);
            let reportRequst = httpAPI({ url: repData.url, method: 'GET', responseType: 'blob', skipErrorHandler: true });
            // при успешном выполнении запроса на формирование отчёта выполнить:
            var onResolve = (response) => {
                this.$notify.success(`${this.$t(repData.text)}`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                this.urls.push(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', repData.type === 'PDF' ? `${this.$t(repData.text)}.pdf` : `${this.$t(repData.text)}.xls` );
                document.body.appendChild(link);
                link.click();
            };
            // при неудачном выполнении запроса на формирование отчёта выполнить:
            var onReject = (error) => {
                errorHandler(error);
            };
            let asyncReportToastMessage = `${ this.$t(repData.text) }`;
            this.$notify.async(reportRequst, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });
        });

        this.$usersHub.$on('on-inactive-detected', async () => {
            if (this.isNotifyInProcess === false) {
                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_IN_PROCESS', true);
                let { confirmed } = await this.$store.dispatch('dialogs/notifyInactive/open', { openedTime: Date.now() });

                if (confirmed) {
                    await httpAPI({ url: `/api/auth/confirm`, method: 'GET' });
                    this.setUserActivityState({ type: 'active', date: this.$moment().valueOf() });
                }
                else
                    await this.$store.dispatch('auth/fetchLogout');

                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_IN_PROCESS', false);
            }
            else
                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_REPEAT_RECIEVED', true);
        });

        this.$usersHub.$on('on-abort-inactive-detected', async () => {
            if (this.isNotifyInProcess)
                this.$store.commit('dialogs/notifyInactive/SET_VISIBLE', false);
                this.$store.commit('dialogs/notifyInactive/SET_NOTIFY_IN_PROCESS', false);
        });

        this.$usersHub.$on('on-user-logout', async () => {
            await this.$store.dispatch('auth/fetchEmergencyLogout');
        });
    },
    beforeDestroy(){
        this.$eventBus.$off('show-employee-information');
        this.$eventBus.$off('show-workplace-information');
        this.$eventBus.$off('show-enterprise-information');
        this.$eventBus.$off('open-document');
        this.$eventBus.$off('open-linked-document');
        this.$eventBus.$off('on-input-file');
        this.$eventBus.$off('on-input-file-to-comment');
        this.$eventBus.$off('on-input-file-to-comment-iqala');
        this.$usersHub.$off('on-inactive-detected');
        this.$usersHub.$off('on-abort-inactive-detected');
        this.$usersHub.$off('on-user-logout');
        this.urls.forEach(url => URL.revokeObjectURL(url));
        this.$eventBus.$off('on-get-report');
    }
};
</script>